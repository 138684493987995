import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Law Firm Case Results - Bartlett & Grippe",
    description:
      "At Bartlett & Grippe, LLC, past results don't guarantee future success, but we've secured $35M+ in settlements and verdicts since 2012. Contact us to see how we can help with your case.",
    heroH1: "Case Results",
    heroH2: "When experience, integrity, and results matter.",
    heroImage: "blg-results.jpg",
    heroImageAlt: "Photo of a check mate in a game of chess",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">Significant Case Results</h2>
            <p className="mb-8">
              Every case is different, and past results are not a guarantee of
              future success. The team at Bartlett & Grippe, LLC is very proud
              of the fact that since opening the firm in April of 2012 through
              January of 2020 they have secured settlements and verdicts on
              behalf of their clients totaling in excess of{" "}
              <strong>$35,000,000</strong>. In 2013 they had the distinction of
              having two of the Connecticut Law Tribune’s top twenty-five
              personal injury jury verdicts. One case dealt with a bar fight,
              and the other a slip in a depression in a parking lot. In January
              of 2020, Bartlett & Grippe was notified that they had one of the
              top verdicts and awards in New England for 2019 Buchanon v. J&A
              Equities, LLC.
            </p>
            <p className="mb-8">
              Many significant settlements are required to be kept confidential
              pursuant to settlement agreements. Below is a sampling of the
              types of cases we have successfully handled for past clients, and
              where allowable the results we have obtained.
            </p>
          </div>
          <div className="mb-16" id="b-v-ja">
            <h2 className="uppercase">Buchanon v. J&A Equities</h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">
              Outcome: $1,200,000 plus confidential settlement
            </h3>
            <p className="mb-8">
              A guest of a tenant at a multifamily property suffered a
              significant spinal cord injury after falling down a poorly lit
              stairway. He was turned down by several firms because his initial
              hospital records stated that he was intoxicated and they felt that
              it was unlikely that there would be any available insurance on the
              property. After thoroughly investigating the matter, Attorney
              Bartlett found numerous safety violations in the stairway, he
              located four commercial insurance policies that provided coverage
              and was able to show, through the use of a toxicologist, that the
              plaintiff’s testimony that he had one to two beers prior to his
              fall was credible. In light of this, Attorney Bartlett was able to
              negotiate a settlement of $1,200,000 with three defendants in
              addition to a confidential settlement with the fourth defendant.
              One of the top 2019 verdicts and settlements in New England.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Workplace Fall results in substantial settlement
            </h2>
            <h3 className="uppercase">Practice Area: Slip and Fall Accident</h3>
            <h3 className="uppercase">Settlement: Confidential</h3>
            <p className="mb-8">
              The Plaintiff slipped and fell on a wet floor while she was at
              work. The Defendants were a company that was hired to manage the
              custodial services at the Plaintiff’s employer. The Defendant
              argued that they were not liabile and that the Plaintiff should
              have been aware that the floor was wet. The Plaintiff sustained a
              torn rotator cuff that resulted in surgery. Attorney Grippe was
              able to secure a very favorable settlement for the Plaintiff.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Motorcycle accident results in settlement of $275,000
            </h2>
            <h3 className="uppercase">
              Practice area: motorcycle/car accident
            </h3>
            <h3 className="uppercase">Outcome: $275,000</h3>
            <p className="mb-8">
              The Plaintiff was riding a motorcyle in New Haven when a vehicle
              turned in front of him causing the vehicles to collide. The
              Plaintiff sustained a fractured leg requring surgery. The
              Defendants disputed liability and argued that the Plaintiff was
              operating is motorcycle at an excessive rate of speed. Attorney
              Grippe was able to secure a settlement of $275,000 despite the
              defendants claims.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Exposure to Legionnaires Disease leads to substantial settlement
            </h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">Settlement: Confidential</h3>
            <p className="mb-8">
              The Plaintiff was exposed to legionella bacteria while staying in
              a hotel. As a result of the exposure the Plaintiff contracted
              Legionnaires disease requiring a long hospitalization. The
              Plaintiff brought a lawsuit against the owner of the hotel as well
              a the manager of the hotel alleging that the water system was not
              properly maintained. Attorney Grippe retained some of the top
              legionnaires experts in the country to assist with the prosecution
              of the case. The Defendants initially disputed that they were at
              fault but eventually offered to engage in a mediation and offered
              a substantil sum to resolve the lawsuit.{" "}
            </p>
          </div>

          <div className="mb-16">
            <h2 className="uppercase">Donna M. v. Siemens</h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">Outcome:$200,000 settlement</h3>
            <p className="mb-8">
              The plaintiff was rear-ended in stop and go traffic on Interstate
              91. After her first attorney told her that her case had very
              little value because there was almost no property damage and she
              had prior injuries to her back, she fired that attorney and hired
              Attorney Bartlett. Attorney Bartlett was able to show how her
              prior back injury made her more susceptible to a subsequent injury
              from a low-speed collision and was able to use this to negotiate a
              $200,000 settlement.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Colleen B. v. 1-3 Long Wharf Drive</h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">Outcome: $520,000 plaintiff’s verdict</h3>
            <p className="mb-8">
              Personal Injury – slip wins $520,000 verdict for woman who
              suffered an ankle sprain and injuries to her lower back which
              required surgery after stepping in a depression in a parking lot.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Trofatter, Kirk v. D & D Builders, LLC
            </h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">Outcome: $375,000 Settlement</h3>
            <p className="mb-8">
              Resident injured after falling through a faulty fire escape at a
              60 unit apartment complex in East Haven. Mr. Kirk Trofatter broke
              his kneecap, upper leg, and left shoulder. The defense argued that
              Mr. Trofatter shouldn’t have been on the fire escape, but during
              the property owner’s deposition by Attorney Bartlett, the owner
              said he inspected the stairs weekly and knew the stairs were
              rusted. Bartlett also found there had been two previous injuries
              on other stairs located at this complex but the owners only
              repaired those specific stairs rather than all the defective
              stairs. The building owner’s insurance company refused to settle
              for higher than $150,000. Judge Jonathan Silbert, after hearing
              the owner knew of the defect and had similar incidents felt that
              the suit was worth $375,000, at which point both sides settled.
            </p>
            <p className="mb-8">
              This case appeared in the Connecticut Law Tribune 02/09/18{" "}
              <strong>
                Man Injured by Collapse of Rusted Steps Reaches $375K Settlement
              </strong>
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Emmanual R. v. Oracle Lounge</h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">Outcome: $449,000 Plaintiff’s Verdict</h3>
            <p className="mb-8">
              $449,000 judgment for a bar patron who suffered a head injury when
              assaulted at a New Haven night club. Bartlett & Grippe, LLC has
              handled many cases related to alcohol-related injuries. They have
              taken cases of this type through the Appellate Court and were
              successful before the Supreme Court.
            </p>
          </div>
          <Link to="/contact-us/">
            <button className="mb-8 mx-auto cta-button-green">
              Contact us today for a free confidential consultation
            </button>
          </Link>
          <div className="mb-16" id="da-v-jf">
            <h2 className="uppercase">
              Seven-year-old child sexually abused 40 years ago awarded
              $3,188,803
            </h2>
            <h3 className="uppercase">Practice Area: Child Abuse</h3>
            <h3 className="uppercase">
              Outcome: Plaintiff’s Verdict of $2,750,000
            </h3>
            <h3 className="uppercase">Dawn Andalora v. Joseph Falanga</h3>
            <p className="mb-8">
              A Willimantic, CT jury awarded Dawn Andalora $2.75 million dollars
              for sexual abuse she alleges she suffered at the hands of her
              mother’s boyfriend between 1974 and 1981. Almost all medical
              records were unavailable. Ms. Andalora feels vindication that it
              wasn’t her fault.
            </p>
            <p className="mb-8">
              11/05/2014 Judge Harry E Calmar issued an order awarding
              additional punitive damages of $438,803.95.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Woman’s Car Struck at Stop Light Receives $125,000 Settlement
            </h2>
            <h3 className="uppercase">Practice Area: Car / Auto Accident</h3>
            <h3 className="uppercase">Outcome: $125,000 Settlement</h3>
            <p className="mb-8">
              A woman was stopped at a stoplight when she was struck from behind
              by a distracted driver sustaining injuries to her head, shoulder,
              and back. Despite prior injuries to the same areas, Attorney
              Bartlett was able to negotiate a favorable settlement on behalf of
              the plaintiff.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">CT Teen Killed in Car Crash</h2>
            <h3 className="uppercase">Practice Area: Wrongful Death</h3>
            <h3 className="uppercase">Outcome: Policy Limit Settlement</h3>
            <p className="mb-8">
              Two CT teens were killed in a car crash after driving down the
              road at a high rate of speed. The driver of the first vehicle left
              the roadway and struck a tree, killing the driver and passenger.
              After being told by numerous attorneys that they did not have a
              case because the second vehicle never struck the first, the family
              came to Attorney Bartlett. After initially receiving a denial from
              the second vehicle’s insurer because there was no contact between
              the vehicles, Attorney Bartlett was able to negotiate a settlement
              for the second vehicle’s full insurance policy limits.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Pedestrian Struck and Killed by Tractor Trailer on Highway. Estate
              Receives $234,000 Settlement
            </h2>
            <h3 className="uppercase">Practice Area: Wrongful Death</h3>
            <h3 className="uppercase">
              Outcome: $234,000 Settlement for Family
            </h3>
            <p className="mb-8">
              A young woman was walking across Interstate 91 when she was struck
              and killed by a passing tractor-trailer. Despite the defendant’s
              arguments that the plaintiff was highly intoxicated and under the
              influence of drugs at the time of the accident, and that the
              tractor-trailer driver was unable to avoid the collision due to
              other pedestrians on the roadway, Attorney Bartlett negotiated a
              favorable settlement for the victim’s family after pursuing claims
              against the tractor-trailer driver as well as the operator of the
              vehicle in which the plaintiff was previously a passenger.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Woman Rear-Ended in Stop and Go Traffic Obtains $70,000 Verdict
            </h2>
            <h3 className="uppercase">Practice Area: Car / Auto Accident</h3>
            <h3 className="uppercase">Outcome: $70,000 Plaintiff Verdict</h3>
            <p className="mb-8">
              A woman was struck from behind in stop and go traffic on the
              Merritt Parkway sustaining injuries to her neck and lower back.
              Due to the nature of the collision and the fact that the plaintiff
              had pre-existing injuries, the defendant insurer refused to
              provide the plaintiff with a reasonable offer. Following a two-day
              trial, the jury provided the plaintiff with the validation she
              deserved, awarding her $70,000.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Tenant Who Fractures Her Ankle on Stairway Receives $150,000
            </h2>
            <h3 className="uppercase">Practice Area: Slip and Fall Accident</h3>
            <h3 className="uppercase">
              Outcome: $150,000 Plaintiff Settlement
            </h3>
            <p className="mb-8">
              An elderly woman tripped and fell on a poorly constructed stairway
              suffering a bimalleolar fracture to her ankle requiring surgery.
              Defense counsel argued that the plaintiff was aware of the alleged
              defect in the stairway due to the fact that she used the stairway
              on a daily basis for the several years in which she was a tenant
              at the property. The defense further argued that the stairway was
              so poorly constructed, that the defects in the stairway were open
              and obvious to the plaintiff. In spite of these arguments,
              Attorney Bartlett was able to obtain a $150,000 settlement for the
              plaintiff.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Man Struck by Freezer Door Recovers $60,000
            </h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">
              Outcome: $60,000 Recovery for Plaintiff
            </h3>
            <p className="mb-8">
              An elderly man was struck by a freezer door that was improperly
              attached during its delivery and installation, sustaining injuries
              to his shoulder and lower back. The defense disputed the extent of
              the plaintiff’s injuries and pointed to the plaintiff’s history of
              multiple work-related injuries as the source of his current
              complaints.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Bicyclist Struck by Car Receives $74,000
            </h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">Outcome: $74,000 Settlement</h3>
            <p className="mb-8">
              A bicyclist was struck by a vehicle emerging from a gas station,
              sustaining injuries to his lower back. Despite a significant
              history of prior injuries and defense witnesses who testified that
              the vehicle was moving at less than 5 miles per hour and the
              defendant was not knocked to the ground, Attorney Bartlett was
              able to negotiate a $74,000 settlement on behalf of the plaintiff.
              Attorney Bartlett was the third attorney representing the
              plaintiff, as the plaintiff fired his first two attorneys after
              they recommended the plaintiff accept the insurance company’s
              offer of $15,000.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Slip and Fall Yields $190,000 Settlement
            </h2>
            <h3 className="uppercase">Practice Area: Slip and Fall Accident</h3>
            <h3 className="uppercase">
              Outcome: $190,000 plaintiff settlement
            </h3>
            <p className="mb-8">
              A man who slipped and fell at an area hotel sustaining injuries to
              his head and neck and a mild traumatic brain injury with resulting
              cognitive deficits settled his claims for $190,000. The hotel and
              property management company alleged that the fall occurred during
              an ongoing storm and further disputed the nature and extent of the
              plaintiff’s injuries. Through the utilization of weather and
              medical experts, Attorney Bartlett was able to reach a settlement
              of the plaintiff’s claims following mediation.
            </p>
          </div>
          <Link to="/contact-us/">
            <button className="mb-8 mx-auto cta-button-green">
              Contact us today for a free confidential consultation
            </button>
          </Link>
          <div className="mb-16" id="jr-v-bsa">
            <h2 className="uppercase">
              $1,000,000 Judgment for Plaintiff in Boy Scout Sexual Abuse Case
            </h2>
            <h3 className="uppercase">Practice Area: Child Abuse</h3>
            <h3 className="uppercase">Outcome:$1,000,000 settlement</h3>
            <p className="mb-8">
              John Roe #1 v. Boy Scouts of America, et al. – $1,000,000 judgment
              obtained against a Boy Scout scoutmaster on behalf of a young boy
              who suffered sexual abuse at scouting events and at a local Boy
              Scout camp. Bartlett & Grippe, LLC is nationally recognized for
              its work related to Boy Scout sexual abuse cases. Many personal
              injury case settlements are confidential and can’t be shown under
              our results.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Workplace fall results in $105,000 settlement
            </h2>
            <h3 className="uppercase">Practice Area: Slip and Fall Accident</h3>
            <h3 className="uppercase">
              Outcome: $105,000 plaintiff settlement
            </h3>
            <p className="mb-8">
              A woman suffers a knee-cap fracture from a workplace fall. The
              plaintiff was working as a parts washer when she was caused to
              slip and fall on a wet floor at her workplace. The defendant
              cleaning company argued that they had posted signs warning of the
              wet floor and that the plaintiff fell on the water she had spilled
              while washing parts. Through depositions of the defendant’s
              employees, Attorney Bartlett was able to develop significant
              inconsistencies in the defendants’ claimed version of events,
              which lead to a $105,000 settlement after mediation.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Homeless man struck and killed by passing vehicle
            </h2>
            <h3 className="uppercase">Practice Area: Wrongful Death</h3>
            <h3 className="uppercase">Outcome: Settlement – policy limit</h3>
            <p className="mb-8">
              A homeless man was walking across the Berlin Turnpike when he was
              struck and killed by a passing vehicle. Despite the fact that the
              police report exonerated the driver of the vehicle, who was a
              prominent medical professional, and found the homeless man
              responsible for the accident, through the use of our own accident
              reconstruction we were able to negotiate a settlement for the full
              policy limits of the driver’s automobile insurance.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Failure to Diagnose Detached Retina results in Confidential
              Settlement
            </h2>
            <h3 className="uppercase">Practice Area: Medical Malpractice</h3>
            <h3 className="uppercase">Outcome: confidential settlement</h3>
            <p className="mb-8">
              Plaintiff presented to a neuro-ophthalmologist after experiencing
              a partial loss of vision in his right eye. He was examined and
              discharged after being told that his loss of vision was permanent
              and that no corrective action could be taken. Several months
              later, the plaintiff began experiencing a distortion of his field
              of vision and was referred to a second neuro-ophthalmologist who
              diagnosed the plaintiff with a detached retina and performed an
              emergency surgical repair. Despite the restoration of the
              plaintiff’s full field of vision, the plaintiff suffered from
              permanent loss of acuity, pain, and fatigue in his right eye as a
              result of the more invasive procedure necessitated by the delay in
              diagnosis. Following mediation, the parties were able to reach a
              confidential settlement.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Disputed liability car accident results in $115,000 Plaintiff’s
              Verdict
            </h2>
            <h3 className="uppercase">Practice Area: Car / Auto Accident</h3>
            <h3 className="uppercase">
              Outcome: Jury Verdict for the Plaintiff $115,000
            </h3>
            <p className="mb-8">
              Cintya Sandi-Torres v. Rong Lou, et al – The plaintiff suffered
              injuries to her neck and lower back following a motor vehicle
              accident wherein her vehicle was struck by another vehicle making
              a left-hand turn in front of her. Although the defendant’s
              disputed liability and argued that the plaintiff’s injuries were
              worth only a few thousand dollars, the jury found the defendant
              100% liable and awarded the plaintiff $115,000
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              CT Auto Injury Verdict for the Plaintiff
            </h2>
            <h3 className="uppercase">Practice Area: Car / Auto Accident</h3>
            <h3 className="uppercase">
              Outcome: Plaintiffs verdict of $55,000
            </h3>
            <p className="mb-8">
              A woman who suffered neck and back injuries in an auto accident
              refused a settlement offer of $4,000 per the advice of Attorney
              Bartlett. After a two day trial, she was awarded $55,000.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              Tenant v. Landlord in a Fire Liability Case
            </h2>
            <h3 className="uppercase">Practice Area: Landlord / Tenant</h3>
            <h3 className="uppercase">Outcome: Confidential Settlement</h3>
            <p className="mb-8">
              Negotiated confidential settlement of a complex fire liability
              case on behalf of 52 tenants of an apartment complex who were
              displaced and whose personal possessions were destroyed in a
              massive fire. Our investigation of the building construction and
              who was at fault led to a satisfactory settlement for all our
              clients that were left homeless.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Frank B. v. Geico Insurance Company</h2>
            <h3 className="uppercase">Practice Area: Motorcycle Accident</h3>
            <h3 className="uppercase">Outcome: $300,000 Settlement</h3>
            <p className="mb-8">
              Motorcycle accident results in a $300,000 policy limit settlement.
              A motorcycle rider who suffered a broken leg after being forced
              off the road by an oncoming car who fled the scene and was never
              identified.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Liane R. v. Johnson Memorial Hospital</h2>
            <h3 className="uppercase">Practice Area: Medical Malpractice</h3>
            <h3 className="uppercase">Outcome: $375,000 Settlement</h3>
            <p className="mb-8">
              $375,000 settlement on behalf of a woman who alleged that
              emergency room physicians at Johnson Memorial Hospital failed to
              diagnose a brain hemorrhage following a fall. The patient suffered
              from injuries that left her with continuing physical and cognitive
              limitations.
            </p>
          </div>
          <Link to="/contact-us/">
            <button className="mb-8 mx-auto cta-button-green">
              Contact us today for a free confidential consultation
            </button>
          </Link>
          <div className="mb-16">
            <h2 className="uppercase">Patient v. Nursing Home</h2>
            <h3 className="uppercase">Practice Area: Wrongful Death</h3>
            <h3 className="uppercase">Outcome: Confidential Settlement</h3>
            <p className="mb-8">
              Wrongful Death – confidential settlement on behalf of the estate
              of a patient who suffered a subdural hematoma as a result of a
              slip and fall accident at a nursing home. The patient’s family
              alleged that, despite numerous prior falls, the nursing home
              failed to take adequate precautions to prevent the patient’s fall.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Tyler F. v. Demkowicz Management, LLC</h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">Outcome: $360,000 plaintiff’s verdict</h3>
            <p className="mb-8">
              Bar fight injury wins $360,000 judgment for a bar patron who
              suffered a broken jaw when assaulted by a former security guard at
              the night club who was terminated just days before the fight.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Wood v. Club, LLC</h2>
            <h3 className="uppercase">Practice Area: Personal Injury</h3>
            <h3 className="uppercase">Outcome: $300,000 plaintiff’s verdict</h3>
            <p className="mb-8">
              $300,000 personal injury verdict for bar patron who was struck on
              the head with a glass bottle sustaining lacerations to his ear and
              injuries to his neck and jaw. Jury finds the bar was held liable.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Wood v. Club, LLC</h2>
            <h3 className="uppercase">Practice Area: Appeals</h3>
            <h3 className="uppercase">
              Outcome: $300,000 plaintiff’s verdict affirmed
            </h3>
            <p className="mb-8">
              Defendant appealed the case to the CT Appellate Court -Attorney
              Frank Bartlett successfully defends the appeal of $300,000
              plaintiff’s verdict. Connecticut Appellate Court affirms the
              judgment, rejecting all of the defendants’ claims.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Wood v. Club, LLC</h2>
            <h3 className="uppercase">Practice Area: Appeals</h3>
            <h3 className="uppercase">
              Outcome: $300,000 plaintiff’s verdict affirmed
            </h3>
            <p className="mb-8">
              Defendant appeals to the Supreme Court -Shortly after oral
              arguments before the Supreme Court, the Supreme Court dismisses
              the defendant’s appeal stating that certification to appeal was
              “improvidently granted”. Watch the video of the trial on the home
              page.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">Estate of Walter R. v. Peggy A.</h2>
            <h3 className="uppercase">Practice Area: Elder Law</h3>
            <h3 className="uppercase">
              Outcome: Plaintiff’s Verdict of $165,000
            </h3>
            <p className="mb-8">
              Elder abuse – $165,000 verdict against a caregiver who had
              misappropriated bank accounts and other property of an elderly
              client.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
